<template>
  <a-layout class="layout">
    <!-- 侧边栏 -->
    <a-layout-sider width="256" v-model="collapsed" :trigger="null" collapsible style="background: #fff">
      <div class="logo">
        <img src="@/assets/imgs/img3.png" alt="" height="32" width="32">
        <div class="logoText" v-if="!collapsed">智慧学习平台</div>
      </div>
      <a-menu :selectedKeys="selectedKeys" theme="light" @select="handlerMenuSelect">
        <a-menu-item v-for="(item) in menus" :key="item.name">
          <a-icon :type="item.meta.icon" />
          <span>{{ item.meta.title }}</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <!-- 头部 -->
      <a-layout-header style="background: #fff; padding: 0;">
        <div class="headerBox">
          <div>
            <a-icon type="menu-unfold" v-if="collapsed" class="trigger" @click="() => (collapsed = !collapsed)" />
            <a-icon type="menu-fold" v-else class="trigger" @click="() => (collapsed = !collapsed)" />
          </div>
          <a-dropdown :trigger="['hover']">
            <div style="cursor: pointer;">
              {{ userInfo.name }}
            </div>
            <template slot="overlay">
              <a-menu>
                <a-menu-item key="1" @click="logout">
                  <a-icon type="logout" />
                  退出登录
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </a-layout-header>
      <!-- 内容区域 -->
      <a-layout-content class="container">
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import { asyncRouterMap } from "@/router/basics.router";
export default {
  data() {
    return {
      collapsed: false,
      menus: [],
    };
  },
  computed: {
    // 计算当前父级路由
    parentRoute() {
      let curRoute = this.menus.find((menu) => {
        return menu.name == this.$route.matched[1].name;
      });
      return curRoute;
    },
    // 计算当前选中的菜单键
    selectedKeys() {
      return [this.parentRoute.name];
    },
    // 计算当前路由名称
    curRouteName() {
      return this.$route.matched[2].name;
    },
    // 计算当前父级路由下的子路由
    childRoutes() {
      return this.parentRoute && this.parentRoute.children
        ? this.parentRoute.children.filter((item) => item.hidden !== true)
        : false;
    },
    // 计算父级路由标题
    parentRouteTitle() {
      return this.parentRoute ? this.parentRoute.meta.title : "";
    },
    // 计算用户信息
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    // 初始化菜单数据
    const routes = asyncRouterMap.find((item) => item.path === "/");
    this.menus = (routes && routes.children) || [];
  },
  methods: {
    // 处理标签页切换
    handlerTabChange(e) {
      this.$router.push({ name: e });
    },
    // 处理登出逻辑
    logout() {
      this.$store.dispatch("user/logout").then((e) => {
        this.$router.push("/login");
      });
    },
    // 处理菜单选择
    handlerMenuSelect({ key }) {
      this.$router.push({ name: key });
    },
  },
};
</script>
<style lang="less">
.layout {
  height: 100vh;

  .logo {
    height: 64px;
    color: #1890ff;
    display: flex;
    align-items: center;
    font-size: 20px;
    box-shadow: 1px 1px 0 0 #e8e8e8;
    padding-left: 24px;

    .logoText {
      margin-left: 12px;
      font-weight: 600;
      height: 64px;
      line-height: 60px;
    }
  }

  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .ant-menu {
    border-inline-end: none !important;
  }

  .container {
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ant-layout-sider-children {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
  }

  .subTabs {
    display: flex;
    background: #fff;
    margin-top: 1px;

    .parentTitle {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      padding: 0 20px;
      height: 54px;
      color: #cccccc;
      background-color: white;
    }

    .tabsBtn {
      .ant-tabs-bar {
        margin-bottom: 0 !important;
      }
    }
  }
}
</style>