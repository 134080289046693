// 导入本地存储模块和登录、获取信息的API
import storage from 'store'
import { login, getInfo } from '@/api/login'
import { ACCESS_TOKEN } from '@/utils/config'

// 定义user模块，包含状态、变更和异步操作
const user = {
  namespaced: true,
  state: () => {
    return {
      token: storage.get(ACCESS_TOKEN), // 初始时从存储中获取token
      userInfo: {} // 初始化用户信息为空对象
    }
  },
  mutations: {
    // 设置token
    SET_TOKEN(state, token) {
      state.token = token
    },
    // 设置用户信息
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo
    },
    // 清除登录状态，包括token和用户信息
    CLEAR_STATE(state) {
      storage.remove(ACCESS_TOKEN) // 从存储中移除token
      state.userInfo = {} // 重置用户信息
    }
  },
  actions: {
    // 执行登录操作
    login(context, params) {
      return new Promise((resolve, reject) => {
        login(params).then(res => {
          if (res.code == 200) {
            const token = res.data
            storage.set(ACCESS_TOKEN, token) // 将获取的token存储起来
            context.commit('SET_TOKEN', token) // 更新state中的token
            resolve(res)
          } else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 获取用户信息
    getUserInfo(context) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          if (res.code == 200) {
            const userInfo = res.data
            context.commit('SET_USER_INFO', userInfo) // 更新用户信息
            resolve(res)
          } else {
            reject(res)
          }
        })
      })
    },
    // 退出登录
    logout(context) {
      return new Promise((resolve) => {
        context.commit('CLEAR_STATE') // 调用清除状态的mutation
        resolve()
      })
    }
  }
}

export default user