import axios from '@/utils/request'

/**
 * 用户登录
 * @param parameter 包含登录参数的对象
 * @returns 返回登录结果的Promise对象
 */
export function login(parameter) {
  return axios({
    url: '/word/api/v1/student/login',
    method: 'post',
    data: parameter
  })
}

/**
 * 获取用户信息
 * @returns 返回用户信息的Promise对象
 */
export function getInfo() {
  return axios({
    url: '/word/api/v1/student/student-detail',
    method: 'get'
  })
}

/**
 * 获取手机验证码
 * @param mobile 用户手机号
 * @returns 返回发送验证码结果的Promise对象
 */
export function getCodeToMobile(mobile) {
  return axios({
    url: '/word/api/v1/student/send-msg/' + mobile,
    method: 'post'
  })
}

/**
 * 校验手机验证码
 * @param data 包含手机号和验证码的对象
 * @returns 返回验证码校验结果的Promise对象
 */
export function checkCode(data) {
  return axios({
    url: `/word/api/v1/student/send-verify/${data.mobile}/${data.code}`,
    method: 'post'
  })
}

/**
 * 获取邮箱验证码
 * @param email 用户邮箱
 * @returns 返回发送邮箱验证码结果的Promise对象
 */
export function getCodeToEmail(email) {
  return axios({
    url: '/word/api/v1/student/send-email/' + email,
    method: 'post'
  })
}

/**
 * 校验邮箱验证码
 * @param data 包含邮箱和验证码的对象
 * @returns 返回邮箱验证码校验结果的Promise对象
 */
export function checkEmailCode(data) {
  return axios({
    url: `/word/api/v1/student/email-verify`,
    method: 'post',
    data
  })
}

/**
 * 重置密码
 * @param data 包含新密码信息的对象
 * @returns 返回重置密码结果的Promise对象
 */
export function resetPwd(data) {
  return axios({
    url: '/word/api/v1/student/change-password',
    method: 'post',
    data
  })
}

/**
 * 学生基础资料修改
 * @param data 包含修改信息的对象
 * @returns 返回修改结果的Promise对象
 */
export function updateUserInfo(data) {
  return axios({
    url: '/word/api/v1/student/update',
    method: 'put',
    data
  })
}

/**
 * 更换手机号
 * @param data 包含新手机号和验证码等信息的对象
 * @returns 返回更换手机号结果的Promise对象
 */
export function updateMobile(data) {
  return axios({
    url: '/word/api/v1/student/change-my-mobile',
    method: 'post',
    data
  })
}

/**
 * 通过邮箱更换邮箱
 * @param data 包含新邮箱和验证码等信息的对象
 * @returns 返回更换邮箱结果的Promise对象
 */
export function updateEmailByEmail(data) {
  return axios({
    url: '/word/api/v1/student/change-my-email-by-email',
    method: 'post',
    data
  })
}

/**
 * 通过手机更换邮箱
 * @param data 包含新邮箱、旧手机号、验证码等信息的对象
 * @returns 返回更换邮箱结果的Promise对象
 */
export function updateEmailByPhone(data) {
  return axios({
    url: '/word/api/v1/student/change-my-email-by-phone',
    method: 'post',
    data
  })
}

/**
 * 更换密码
 * @param data 包含新密码信息的对象
 * @returns 返回更换密码结果的Promise对象
 */
export function updatePassword(data) {
  return axios({
    url: '/word/api/v1/student/change-my-password',
    method: 'post',
    data
  })
}