import { vipRole } from "@/utils/common";


function hasRole(state, type) {
  return state.user.userInfo.auths && state.user.userInfo.auths.split(',').includes(type)
}

const getters = {
  userInfo: state => state.user.userInfo,
  qiniuDomain: state => state.qiniuDomain,
  hasWrodRole: state => hasRole(state, vipRole.WORD),
  hasTranslateRole: state => hasRole(state, vipRole.TRANSLATE),
  hasListenRole: state => hasRole(state, vipRole.LISTEN)
}

export default getters
