import BasicLayout from '@/layout/BasicLayout'
import RouteView from '@/layout/RouteView'
export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    redirect: '/words/list',
    meta: { title: '控制台', },
    children: [
      {
        path: 'words',
        name: 'words',
        component: RouteView,
        meta: { title: '词汇', icon: 'folder' },
        redirect: '/words/list',
        hideChildrenInMenu: true,
        children: [
          {
            path: 'list',
            name: 'wordList',
            component: () => import('@/views/words/list'),
            meta: { title: '词汇试卷', keepAlive: true }
          },
          {
            path: 'wordLib',
            name: 'wordLib',
            component: () => import('@/views/words/wordLib'),
            meta: { title: '词库', keepAlive: true }
          },
          {
            path: 'detail',
            name: 'wordDetail',
            component: () => import('@/views/words/detail'),
            meta: { title: '词汇详情' }
          },
        ]
      },
      {
        path: 'article',
        component: RouteView,
        name: 'article',
        meta: { title: '机经', icon: 'read' },
        redirect: '/article/list',
        children: [
          {
            path: 'list',
            name: 'articleList',
            component: () => import('@/views/article/list'),
            meta: { title: '机经' }
          },
          {
            path: 'detail',
            name: 'articleDetail',
            component: () => import('@/views/article/articleDetail'),
            meta: { title: '机经详情' },
          },
        ]
      },
      {
        path: 'articlePaper',
        component: RouteView,
        name: 'articlePaper',
        meta: { title: '预测', icon: 'read' },
        redirect: '/articlePaper/list',
        children: [
          {
            path: 'list',
            name: 'articlePaperList',
            component: () => import('@/views/articlePaper/list'),
            meta: { title: '预测' }
          },
          {
            path: 'articleList',
            name: 'articlePaperArticleList',
            component: () => import('@/views/articlePaper/articleList'),
            meta: { title: '预测' }
          },
        ]
      },
      {
        path: 'personal',
        component: () => import('@/views/personal/index'),
        redirect: '/personal/basic',
        name: 'personal',
        meta: { title: '我的', icon: 'user' },
        children: [
          {
            path: 'basic',
            name: 'personalBasic',
            component: () => import('@/views/personal/basic/basic'),
            meta: { title: '基本资料' }
          },
          {
            path: '/personal/basic/edit',
            name: 'personalBasicEdit',
            component: () => import('@/views/personal/basic/basicEdit'),
            meta: { title: '基本资料编辑' }
          },
          {
            path: 'collect',
            name: 'personalCollect',
            component: () => import('@/views/personal/collect/collect'),
            meta: { title: '我的收藏' }
          },
          {
            path: 'security',
            name: 'personalSecurity',
            component: () => import('@/views/personal/security/security'),
            meta: { title: '安全设置' }
          },
          {
            path: '/personal/security/updatePhone',
            name: 'updatePhone',
            component: () => import('@/views/personal/security/updatePhone'),
            meta: { title: '更换手机' }
          },
          {
            path: '/personal/security/updateEmail',
            name: 'updateEmail',
            component: () => import('@/views/personal/security/updateEmail'),
            meta: { title: '更换邮箱' }
          },
          {
            path: '/personal/security/phoneUpdateEmail',
            name: 'phoneUpdateEmail',
            component: () => import('@/views/personal/security/phoneUpdateEmail'),
            meta: { title: '手机号验证' }
          },
          {
            path: '/personal/security/emailUpdateEmail',
            name: 'emailUpdateEmail',
            component: () => import('@/views/personal/security/emailUpdateEmail'),
            meta: { title: '邮箱验证' }
          },
          {
            path: '/personal/security/updatePassword',
            name: 'updatePassword',
            component: () => import('@/views/personal/security/updatePassword'),
            meta: { title: '更换密码' }
          },
          {
            path: '/personal/security/phoneUpdatePassword',
            name: 'phoneUpdatePassword',
            component: () => import('@/views/personal/security/phoneUpdatePassword'),
            meta: { title: '手机号验证' }
          },
          {
            path: '/personal/security/emailUpdatePassword',
            name: 'emailUpdatePassword',
            component: () => import('@/views/personal/security/emailUpdatePassword'),
            meta: { title: '邮箱验证' }
          },
          {
            path: 'vip',
            name: 'personalVip',
            component: () => import('@/views/personal/vip/vip'),
            meta: { title: '会员中心' }
          }
        ]
      },
    ]
  },
  {
    path: '/learn',
    name: 'learn',
    meta: { title: '练习' },
    component: () => import('@/views/words/study')
  },
  {
    path: '/agreement',
    name: 'agreement',
    meta: { title: '协议' },
    component: () => import('@/views/personal/vip/agreement')
  },
  {
    path: '/articleHomework',
    name: 'articleHomework',
    meta: { title: '课后练习' },
    component: () => import('@/views/article/articleHomework')
  },
]

export const constantRouterMap = [
  {
    path: '/login',
    name: 'login',
    component: () => import('/src/views/login/login')
  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('/src/views/login/forget')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/exception/404.vue')
  }
]