/**
 * 初始化并配置Vue Router
 * 使用asyncRouterMap和constantRouterMap来定义路由
 * 并通过permission函数对路由进行权限控制
 * 
 * @param none
 * @return {VueRouter} 返回配置好的VueRouter实例
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import { asyncRouterMap, constantRouterMap } from './basics.router'
import { permission } from './permission'
Vue.use(VueRouter)

// 创建VueRouter实例，并将constantRouterMap和asyncRouterMap合并为路由配置
const router = new VueRouter({
  routes: constantRouterMap.concat(asyncRouterMap)
})

// 对路由进行权限控制
permission(router)
export default router