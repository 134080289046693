// 导入 qiniu-js 库
import * as qiniu from 'qiniu-js'
import { qiniuDomain } from '@/utils/config'
/**
 * 上传文件到七牛云
 * @param file 要上传的文件对象
 * @param token 上传所需的 token
 * @param callback 上传完成后的回调函数
 */
export function uploadFile(file, token, callback) {
  // 生成基于时间的文件名以避免重复
  let timeName = new Date().getTime()
  // 设置上传的额外参数，如文件名和自定义参数
  const putExtra = {
    fname: timeName + file.name.substr(file.name.lastIndexOf('.')), // 拼接文件名
    params: {}, // 自定义参数
  }
  // 配置上传行为，如使用 CDN 域名
  const config = { useCdnDomain: true }
  // 创建上传任务
  const observable = qiniu.upload(file, putExtra.fname, token, putExtra, config)
  // 订阅上传进度和结果
  observable.subscribe({
    next: (result) => {
      // 输出上传进度
      console.warn(result)
    },
    complete: (res) => {
      // 上传完成后的处理
      callback(res)
    },
  })
}

/**
 * 获取音频的URL
 * @param {string} url - 音频资源的URL，可以是相对路径或绝对路径
 * @return {string|undefined} 返回处理后的音频URL，如果输入非字符串则不返回任何内容
 */
export function getAudioUrl(url) {
  // 检查url参数是否为字符串类型
  if (typeof url != "string") {
    return;
  }
  // 如果url是http或https开头的绝对路径，则直接返回该url，否则返回加上七牛域名的url
  return url.indexOf("http") != -1 ? url : qiniuDomain + url;
}

/**
 * 在路由离开前的钩子函数生成器。
 * 根据提供的路由列表，对将要离开的路由进行判断，设置其元信息中的keepAlive属性。
 * 
 * @param {Array} list - 包含需要保持活跃的路由名称的数组。
 * @returns {Function} 返回一个路由钩子函数，用于在路由离开前修改其元信息。
 */
export function beforeRouteLeave(list) {
  return function (to, from, next) {
    // 根据将要前往的路由名称，决定是否保持当前路由的活跃状态
    if (list.includes(to.name)) {
      from.meta.keepAlive = true; // 如果to.name在列表中，设置from路由的keepAlive为true
    } else {
      from.meta.keepAlive = false; // 否则，设置为false
    }
    next(); // 进行下一个路由导航
  }
}

// 出口：提供考试主题列表
export const subjectList = ['TOEFL']
// 提供包含考试主题的详细信息列表
export const subjectLeafList = [
  {
    value: 1,
    label: 'TOEFL',
    isLeaf: false,
  },
]

// 出口：年级列表
export const gradeList = ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级', '七年级', '八年级', '九年级', '十年级', '十一年级', '十二年级']

//会员权限
export const vipRole = {
  TRANSLATE: '1', //原文翻译
  LISTEN: '2',//音频试听
  WORD: '3'//单词真题
}