import storage from 'store'
import router from '@/router'
import axios from "axios";
import { message } from 'ant-design-vue'
import { ACCESS_TOKEN } from '@/utils/config'

const request = axios.create({
  baseURL: '',
  timeout: 10000
})

/**
 * @desc: 异常拦截处理器
 * @param { Object } error 错误信息
 */
const errorHandler = error => {
  message.error(error.message || error.msg)
  return Promise.reject(error)
}

/**
 * @desc: 请求发送前拦截
 */
request.interceptors.request.use(config => {
  config.headers['token'] = storage.get(ACCESS_TOKEN) || ''
  return config
}, errorHandler)


/**
 * @desc: 服务端响应后拦截
 */
request.interceptors.response.use(response => {
  const result = response.data
  if (result.code === 200 || !result.code) {
    return result
  } else if (result.code === 401 || result.code === 403) {
    // 登录失效
    errorHandler(result)
    storage.remove(ACCESS_TOKEN)
    router.push({ path: '/login' })
    return result
  } else {
    errorHandler(result)
    return result
  }
}, errorHandler)

export default request
