<template>
  <div id="app">
    <ConfigProvider :locale="locale">
      <router-view />
    </ConfigProvider>
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN"; // 导入 Ant Design Vue 的中文语言包
import { ConfigProvider } from "ant-design-vue"; // 导入 ConfigProvider 组件
export default {
  components: { ConfigProvider }, // 注册 ConfigProvider 组件
  data() {
    return {
      locale: zhCN, // 设置应用的语言环境为中文
    };
  },
};
</script>