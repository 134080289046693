// 导入本地存储模块和 Vuex store
import storage from 'store'
import store from '@/store'
// 导入访问令牌常量
import { ACCESS_TOKEN } from '@/utils/config'

// 无需权限验证的页面白名单
export const whiteList = ['/login', '/forget']

// 登录页面路径
const loginPath = '/login'
// 默认页面路径
const defultPath = '/'

/**
 * 全局路由守卫，用于权限验证
 * @param {Object} router - Vue Router 实例
 */
export const permission = (router) => {
  // 在每个路由跳转前执行
  router.beforeEach((to, from, next) => {
    // 检查本地存储中是否存在访问令牌
    if (storage.get(ACCESS_TOKEN)) {
      // 如果当前路径是登录页且已有令牌，则重定向到默认页面
      if (to.path === loginPath) {
        next({ path: defultPath })
      } else {
        // 如果用户信息未加载，尝试获取用户信息
        if (!Object.keys(store.state.user.userInfo).length) {
          store.dispatch('user/getUserInfo').then(() => {
            next()
          }).catch(() => {
            // 获取用户信息失败，清除用户状态并重定向到登录页
            store.commit('user/CLEAR_STATE')
            next({ path: loginPath })
          })
        } else {
          // 用户信息已加载，继续跳转
          next()
        }
      }
    } else {
      // 未登录时，如果请求的页面在白名单内，则放行，否则重定向到登录页
      if (whiteList.includes(to.path)) {
        next()
      } else {
        next({ path: loginPath })
      }
    }
  })
}